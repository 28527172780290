import { html, css } from 'Elements';
import TableBasic from 'Components/abstract/TableBasic.js'
import { Lang } from 'Utils';

class AdminHolidaysListPage extends TableBasic {
  static get styles() {
    return [
      super.styles,
      css`
        table tr {
          padding:0;
        }

        table td {
          padding:0px;
          padding-left: 7px;
          padding-bottom: 0px;
        }

        .center {
          padding:0px;
        }

        m-icon[name="check"] {
          color:var(--sl-color-success-700);
          font-size:1.6em;
          margin-top:2px;
        }

        table tbody tr:hover {
          outline:0px;
          outline-offset: -1px;
          cursor:default;
        }
      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'Public holidays',
            empty:'No items available',
            holiday:'public holidays',
            date:'Date',
          }
        },
        french:{
          translation: {
            title:'Jours fériés',
            empty:'Aucun élement disponible',
            holiday:'jour(s) férié(s)',
            date:'Date',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.layout = 'large';
    this.apiEndpoint = 'private/admin/system/holidays';
    this.urlVar = 'idd';
    this.itemTitleField = 'title';
  }

  _getTableHeader() {
    const totalCount = this.items.length || 0;
    const totalText = this._tl('holiday');
    return `${totalCount} ${totalText}`;
  }

  getDate(item) {
    return item.day;
  }

  _renderItems() {
    if (!this.items?.length) return;

    return html`
      <table ${this.loading ? 'disabled': ''}>
        <thead>
          <tr>
            <th width="200">${this._tl('date')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>      
        ${this.items.map((item) => html`
          <tr>
            <td>${this.getDate(item)}</td>
            <td>${Lang.lookup(item, 'name')}</td>
          </tr>
        `)}
        </tbody>
      </table>
    `;
  }

  render() {    
    return html`
      <section-header backroute="../../" micon="captive_portal">${this._tl('title')}</section-header>
      <br/>
      ${this._renderHeader()}  
      ${this._renderNoItem()}
      ${this._renderItems()}
      <br/><br/><br/>
    `;
  }
}

customElements.define('page-sys-holidays-list', AdminHolidaysListPage);

import { html, css, formCss } from 'Elements';
import TableBasic from 'Components/abstract/TableBasic.js';
import { Fetcher, Session, Sleep } from 'Utils';

class BackupRestorePage extends TableBasic {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        :host {
          font-family:Calibri;
        }

        .red {
          color:var(--sl-color-danger-500);
          font-weight:bold;
          text-align:center;
          margin:20px;
        }

        sl-radio-group::part(form-control-label) {
          font-size:0.9em;
        }

        sl-radio::part(label) {
          font-size:0.8em;
        }

        #modal-restore::part(body) {
          min-width:800px;
        }

        .flex {
          display:flex;
          gap:20px;
          justify-content:space-around;
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english: {
          translation: {
            title:'Backup & Restore',
            empty:'No backup available',
            backup:'Backup',
            backup_upload:'Upload a backup',
            restore:'Restore',
            restore_confirm:'Restoration of',
            file:'file',
            backup_confirm:'Confirm backup creation',
            download:'Download'
          },
        },
        french: {
          translation: {
            title:'Sauvegarde & Restauration',
            empty:'Aucune sauvegarde disponible',
            backup:'Sauvegarder',
            backup_upload:'Téléverser une sauvegarde',
            restore_confirm:'Restauration de',
            restore:'Restaurer',
            file:'fichier',
            backup_confirm:'Confirmer la création de la sauvegarde',
            download:'Télécharger'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.layout = 'large';
    this.isFirstSocketMessage = true;
    this.apiEndpoint = 'private/admin/system/backup_restore';
    this.apiEndpointUpload = 'private/admin/upload/backup';
    this.urlVar = 'idb';
    this.itemTitleField = 'name';
    this.socketOptions = { room:'restore', event:'taskProgress' };
    this.joinRoom = this.joinRoom.bind(this);
    this.onTaskProgress = this.onTaskProgress.bind(this);
   }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('socket-reconnected', this.joinRoom);
    this.joinRoom();
  }

  joinRoom() {
    Session.sockets.boss.onMessages(this.socketOptions, this.onTaskProgress);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('socket-reconnected', this.joinRoom);
    Session.sockets.boss.leave(this.socketOptions);
  }

  async onTaskProgress(data) {
    if (this.isFirstSocketMessage) {
      this.isFirstSocketMessage = true;
      // hide form in modalRestore
      this.modalRestore.querySelector('form').style.display = 'none';
    }
    this.modalRestore.progressMessage = data.progressMessage;
    this.modalRestore.progress = data.progress;

    if (data.progress === 100) {
      await Sleep(500);
      this.modalRestore.progress = null;
      this.modalRestore.progressMessage = null;
      this.modalRestore.querySelector('form').style.display = 'block';
      this.modalRestore.qs('#restore_result').innerHTML = 'resultat ici';
      this.modalRestore.qs('#restore_result').style.display = 'block';
      this.modalRestore.resetButtons();
    }
  }

  renderNoItem() {
    if (this.items && this.items.length) return;
    return html`
      <div class="center">
        <p>${this._tl('empty')}</p>
      </div>
    `;
  }

  _getItemTitle(item) {
    return item.name;
  }

  _getTableHeader() {
    const count = this.items.length;
    const text = count > 1 ? `${this._tl('file')}s` : this._tl('file');
    return ` ${count} ${text}`;
  }


  backupConfirm() {
    this.modalBackup = this.modalBackup || this.shadowRoot.getElementById('modal-backup');
    this.modalBackup.show();
  }

  async doBackup() {
    this.modalBackup.loadingButtons();
    await Fetcher.post(this.apiEndpoint);
    this.modalBackup.resetButtons();
    this.modalBackup.hide();
    this.loadItems();
  }

  restoreConfirm(ev, item) {
    this.item = item;
    this.modalRestore = this.modalRestore || this.shadowRoot.getElementById('modal-restore');
    this.modalRestore.querySelector('.itemTitle').textContent = item.name;
    this.modalRestore.show();
    this.modalRestore.querySelector('sl-button[variant="primary"]').disabled = true;
    this.checkFormRestore();
  }

  sendBackup() {
    this.shadowRoot.getElementById('fileInput').click();
  }

  downloadBackup(ev, item) {
    const url = `/api/v2/${this.apiEndpoint}/${item.name}`;
    document.location.href = url;
  }

  async _handleFileSelect(event) {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      await Fetcher.upload(this.apiEndpointUpload, formData);
      this.loadItems();
    }
  }

  _renderHeader() {
    return html`
      <section-header size="vsmall">
        ${this.loading 
          ? this._renderLoader()
          : html`
            ${this._getTableHeader()}
            <div slot="right">
              <sl-button variant="primary" size="small" @click=${this.backupConfirm}>${this._tl('backup')}</sl-button>
              <sl-button variant="primary" size="small" @click=${this.sendBackup}>${this._tl('backup_upload')}</sl-button>
            </div>`
        }
      </section-header>`
  }

  formatSize(size) {
    const units = ['octets', 'Ko', 'Mo', 'Go', 'To'];
    let unitIndex = 0;
    let formattedSize = size;

    while (formattedSize >= 1024 && unitIndex < units.length - 1) {
      formattedSize /= 1024;
      unitIndex++;
    }

    return `${formattedSize.toFixed(2)} ${units[unitIndex]}`;
  }

  _renderItems() {
    if (!this.items?.length) return;

    return html`
      <table ${this.loading ? 'disabled': ''}>
        <thead>
          <tr>
            <th>Fichier</th>
            <th>Taille</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>      
        ${this.items.map((item) => html`
          <tr @click="${(ev) => this._editItem(ev, item)}">
            <td>${item.name}</td>
            <td>${this.formatSize(item.size)}</td>
            <td class="icon">
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${(ev) => this.downloadBackup(ev, item)}">
                    <m-icon slot="prefix" name="download"></m-icon>
                    ${this._tl('download')}
                  </sl-menu-item>
                  <sl-menu-item @click="${(ev) => this.restoreConfirm(ev, item)}">
                    <m-icon slot="prefix" name="repartition"></m-icon>
                    ${this._tl('restore')}
                  </sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item @click="${(ev) => this._deleteItemConfirm(ev, item)}">
                    <m-icon slot="prefix" name="delete"></m-icon>
                    ${this._tl('remove')}
                  </sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
          </tr>
        `)}
        </tbody>
      </table>
    `;
  }

  _renderNoItem() {
    if (this.loading || this.items?.length) return;
    return html`<div class="center">
      <br/><br/>
      ${this._tl('empty')}
      <br/><br/>
    </div>`;
  }

  checkFormRestore() {

    this.isFirstSocketMessage = true;
    this.modalRestore.querySelector('form').style.display = 'block';
    this.modalRestore.progress = null;
    this.modalRestore.progressMessage = null;
    this.modalRestore.qs('#restore_result').innerHTML = '';
    this.modalRestore.qs('#restore_result').style.display = 'none';
    this.modalRestore.resetButtons();

    const existingRecords = this.modalRestore.qs('sl-radio-group[name="existingRecords"]').value;
    const newRecords = this.modalRestore.qs('sl-radio-group[name="newRecords"]').value;
    const operation = this.modalRestore.qs('sl-radio-group[name="operation"]').value;
    const existingCollections = this.modalRestore.qs('sl-radio-group[name="existingCollections"]').value;
    let collections = this.modalRestore.qs('sl-select[name="collections"]').value;
    this.restoreConfig = null;

    if (collections.includes('all')) {
      // reset sl-select value with only 'all'
      this.modalRestore.qs('sl-select[name="collections"]').value = ['all'];
      collections = ['all'];
    }

    if (!collections || !existingRecords || !newRecords) {
      this.modalRestore.qs('sl-button[variant="primary"]').disabled = true;
      return;
    }

    this.modalRestore.qs('sl-button[variant="primary"]').disabled = false;
    this.restoreConfig = { collections, existingRecords, newRecords, operation, existingCollections };
  }

  async doRestore() {
    if (!this.restoreConfig) return;
    this.modalRestore.qs('#restore_result').style.display = 'none';
    this.modalRestore.loadingButtons();
    await Fetcher.put(`${this.apiEndpoint}/${this.item.name}`, this.restoreConfig);
  }

  render() {

    const v = ['system_soc_classifications', 'system_soc_countermeasures', 'customer_template_email', 'customer_template_sms', 'system_languages'].join(' ');

    return html`
      <section-header backroute="../../" micon="settings_backup_restore">${this._tl('title')}</section-header>
      <br/>
      ${this._renderHeader()}  
      ${this._renderNoItem()}
      ${this._renderItems()}
      ${this._renderDeleteModal()}

      <input type="file" id="fileInput" accept=".zip" style="display: none;" @change="${this._handleFileSelect}">

      <modal-dialog id="modal-backup" modal>
        ${this._tl('backup_confirm')} ?
        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" @click=${this.doBackup}>${this._tl('backup')}</sl-button>
      </modal-dialog>

      <modal-dialog id="modal-restore" label="Paramètres de restauration" modal>
        ${this._tl('restore_confirm')} &laquo;<span class="itemTitle"></span>&raquo;
        <br/><br/>
        <form>
          <sl-select size="small" clearable multiple label="Restaurer quoi ?" name="collections" value="${v}" @sl-change=${this.checkFormRestore}>
            <sl-option value="system_soc_classifications">SOC: classifications</sl-option>
            <sl-option value="system_soc_countermeasures">SOC: contre-mesures</sl-option>
            <sl-option value="customer_template_email">Templates: emails</sl-option>
            <sl-option value="customer_template_sms">Templates: SMS</sl-option>
            <sl-option value="system_languages">Langues disponibles</sl-option>
            <sl-option value="all">Tout</sl-option>
          </sl-select>

          <br/>
          <div class="flex">

            <sl-radio-group label="Elements déjà existants" name="existingRecords" value="doNothing" @sl-change=${this.checkFormRestore}>
              <sl-radio value="doNothing">Ne rien faire</sl-radio>
              <sl-radio value="doUpdate">Mettre à jour</sl-radio>
            </sl-radio-group>

            <sl-radio-group label="Nouveaux éléments" name="newRecords" value="doInsert" @sl-change=${this.checkFormRestore}>
              <sl-radio value="doNothing">Ne rien faire</sl-radio>
              <sl-radio value="doInsert">Ajouter</sl-radio>
            </sl-radio-group>

            <sl-radio-group label="Collections existantes" name="existingCollections" value="doNothing" @sl-change=${this.checkFormRestore}>
              <sl-radio value="doNothing">Ne pas vider</sl-radio>
              <sl-radio value="doRecreate">Vider complètement</sl-radio>
            </sl-radio-group>

            <sl-radio-group label="Opération" name="operation" value="doSimulate" @sl-change=${this.checkFormRestore}>
              <sl-radio value="doSimulate">Simuler</sl-radio>
              <sl-radio value="doExec">Exécuter</sl-radio>
            </sl-radio-group>

          </div>
          <br/><br/>
        </form>

        <box-styled id="restore_result">
        </box-styled>

        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" disabled @click=${this.doRestore}>${this._tl('restore')}</sl-button>
      </modal-dialog>
    `;
  }
}

customElements.define('page-sys-backup-restore', BackupRestorePage);
import { PageElement, html, css, formCss } from 'Elements';
import { Session } from 'Utils';

import './table.js';
import './filters.js';

class EventsPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        sl-badge {
          font-size:0.5em;
          position:absolute;
          top:5px;
          margin-left:10px;
          cursor:pointer;
        }
      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'Events',
          }
        },
        french:{
          translation: {
            title:'Evènements',
          },
        }
      }
    ]
  }

  static get properties() {
    return {
      loading: { type: Boolean },
      newEvents: { type: Number },
      groupBy: { type: String },
    };
  }

  constructor() {
    super();
    this.layout = 'large';
    this.socketOptions = { room:'system_events', event:'add' };
    this.newEvents = 0;
    this.joinRoom = this.joinRoom.bind(this);
    this.onNewEvent = this.onNewEvent.bind(this);
    this.groupBy = 'day';
  }
  
  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('socket-reconnected', this.joinRoom);
    this.joinRoom();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('socket-reconnected', this.joinRoom);
    Session.sockets.boss.leave(this.socketOptions);
  }

  joinRoom() {
    Session.sockets.boss.onMessages(this.socketOptions, this.onNewEvent);
  }

  onNewEvent() {
    this.newEvents+=1;
    this.table = this.table || this.qs('sys-events-table');
    this.table.pushData(ev);
  }

  reloadData() {
    this.table = this.table || this.qs('sys-events-table');
    this.table.reloadData();
  }

  render() {    
    return html`
      <section-header backroute="../../" micon="calendar_clock">
        <div style="position:relative;">
          ${this._tl('title')}
          ${this.newEvents
            ? html`<sl-badge variant="primary" pill pulse @click=${this.reloadData}>${this.newEvents}</sl-badge>`
            : ''
          }
        </div>
      </section-header>

      <sl-progress-bar style="--height: 2px;" indeterminate></sl-progress-bar>

      <data-search-toolbar .parent=${this}>
        <sys-events-filters .parent=${this}></sys-events-filters>
      </data-search-toolbar>

      <sys-events-table groupBy=${this.groupBy} .parent=${this}></sys-events-table>
    `;
  }
}

customElements.define('page-sys-events', EventsPage);

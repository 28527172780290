import { FullPageElement, html, css, formCss } from 'Elements';
import { UrlParams } from 'Utils';
import config from './config.js';

class Filters extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        :host {
          margin-top:5px;
        }

        sl-option::part(base) {
          line-height:initial;
          padding:0px;
          font-size:0.95em;
        }

        sl-option m-icon {
          font-size:1.2em;
          float:left;
          margin-right:5px;
        }

        .filters {
          display:flex;
          align-items:center;
          justify-content:space-around;
          gap:5px;
          width:100%;
        }

        .filters .left {
          display:flex;
          justify-content: space-between;
          align-items:baseline;
          width:100%;
        }

        .filters .right {
          display:flex;
          align-items:center;
          justify-content:flex-end;
          gap:5px;
          width:100%;
        }

        @media (max-width: 670px) {
          .filters {
            flex-direction: column;
            gap: 10px;
          }
        }

      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      config.translations,
      {
        english:{
          translation: {
            placeholder:'All events',
          }
        },
        french:{
          translation: {
            placeholder:'Tous les évènements',
          },
        }
      }
    ]
  }

  constructor() {
    super();
    const events = UrlParams.get('events');
    this.selectedEvents = events ? events.replace(',', ' ') : '';
  }

  onEventsChange(ev) {
    this.selectedEvents = this.qs('sl-select[name="f"]').value.join(',');
    this.selectedEvents.length ? UrlParams.set('events', this.selectedEvents) : UrlParams.del('events');
    UrlParams.del('p');
    this.reloadData();
  }

  reloadData() {
    this.parent.reloadData();
  }

  render() {    
    return html`
      <div class="filters">
        <div class="right">
          <sl-select 
            hoist 
            max-options-visible="1" 
            placeholder="${this._tl('placeholder')}" 
            style="width:350px" 
            multiple 
            clearable 
            size="small" 
            name="f" 
            value="${this.selectedEvents}"
            @sl-change="${this.onEventsChange}"
          >
            ${Object.keys(config.events).map(e => {
              const event = config.events[e];
              return html`
                <sl-option size="small" value="${e}">
                  <m-icon name="${event.icon}" style="color:${event.iconColor};"></m-icon>
                  ${this._tl(`events.${e}`)}
                </sl-option>`
            })}
          </sl-select>
        </div>
      </div>
    `;
  }
}

customElements.define('sys-events-filters', Filters);
